import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'AddRuleMaster',
  components: {},
  props: ['dunningMasterId'],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      dunningMasterRowDetail: null,
      showAddLetterMasterModal: false,
      ruleId: null,
      ruleMasterId: null,
      ruleMasterType: null,
      editMode: false,

      active: false,
      parent_value_set_id: null,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
    };
  },
  validations: {
    ruleMasterType: {
      required
    }
  },
  mounted() {
    if (this.dunningMasterId) {
      this.getDunningRuleMasterDataById(this.dunningMasterId);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addUpdateRuleMasterData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getDunningRuleMasterDataById(dunningMasterId) {
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningRuleMasterDataById', dunningMasterId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningRuleMasterData = resp.data.data;
            const result = resp.data.data;
            this.ruleId = result.rule_master_id;
            this.ruleMasterType = result.rule_master_name;
            this.active = result.is_activated === 'Y' ? true : false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addUpdateRuleMasterData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          rule_master_id: this.ruleId,
          rule_master_name: this.ruleMasterType,
          is_activated: this.active ? 'Y' : 'N'
        };
        this.loader = true;
        this.$store
          .dispatch('dunningAdmin/addUpdateRuleMasterData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
