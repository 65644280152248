import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addRuleMaster from './addRuleMaster';
// import { required } from 'vuelidate/lib/validators';
export default {
  name: 'DunningRuleMaster',
  watch: {
    currentPage: function() {
      this.getDunningRuleMasterList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDunningRuleMasterList();
    }
  },
  components: {
    addRuleMaster
  },
  data() {
    return {
      dunningMasterId:null,
      dunningMasterRowDetail:null,
      showAddRuleMasterModal: false,
      ruleMasterId: null,
      ruleMasterType: null,
      active: true,
      ruleId: null,
      mapLetter: {
        value: null,
        text: null
      },
      ruleName: null,
      executionDays: null,
      OutstandingFrom: null,
      OutstandingTo: null,

      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,

      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,

      showAdvSearchModal: false,
      vsetCode:null,
      setTimeVsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      periodType: null,
      OperatingUnitList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      dunningRuleMasterData: [
      ],
      dunningRuleMasterFields: [
        {
          key: 'rule_master_name',
          label: 'Rule Master Name'
        },
        {
          key: 'is_activated',
          label: 'Active'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddRuleMasterModal = true;
          this.dunningMasterRowDetail = null;
          this.dunningMasterId =null;
        }
      }
    });
  },
  methods: {
    getDunningRuleMasterList() {
  
      const payload = {
        rule_master_name: this.ruleMasterType,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningRuleMasterList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningRuleMasterData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_MASTER_LIST) {
        this.ruleMasterId = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_LETTER_MASTER_LIST
      ) {
        this.mapLetter.text = item.value_code;
        this.mapLetter.value = item.value_set_dtl_id;
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.ruleMasterType = null,
      this.dunningRuleMasterData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
    },
      rowSelected(item) {
        this.showAddRuleMasterModal = item;
        this.dunningMasterId=item.rule_master_id;
        this.showHideRuleMasterModal(true);
    },
    showHideRuleMasterModal(flag) {
       this.showAddRuleMasterModal = flag;
    if (!flag) {
      this.getDunningRuleMasterList();
    }
    },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
